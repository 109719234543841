/* body{
  background-color: #282c34;
  color: grey;
} */
.js-header{
    background-color: transparent;
  }
.js-logo img{
    max-width: 300px;
    height: auto;
    background-color: transparent;
  }
.js-sazerac{
  width:300px;
  height: auto;
}

  